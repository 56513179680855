import React, { useState } from "react";
import Select, { SelectOption } from "@amzn/meridian/select";
import Text from "@amzn/meridian/text";
import { TableDataRow } from "src/store/siteConfigPageSlice";
import { ErrorRecord } from "src/hooks/useForm";
import { useAppSelector } from "src/store/store";
import Row from "@amzn/meridian/row";
import { createSearchRegExp } from "src/utils/searchUtils";
import Input from "@amzn/meridian/input";
import Checkbox from "@amzn/meridian/checkbox";

interface siteFormFieldsProp {
  formData: TableDataRow;
  errors: ErrorRecord<TableDataRow>;
  handleChange: (
    key: keyof TableDataRow,
    value: any,
    sanitizeFn?: (value: any) => any
  ) => void;
}

const siteFormFields = ({
  formData,
  errors,
  handleChange,
}: siteFormFieldsProp) => {
  const { countries, regions, orgs, subOrgs, buildingTypes } = useAppSelector(
    (state) => state.siteConfigPage
  );

  const [searchQuery, setSearchQuery] = useState();
  const searchRegExp = createSearchRegExp(searchQuery);
  const matchedCountryOptions = countries
    .map((country) => ({ label: country.name, value: country.name }))
    .filter((option) => !searchQuery || searchRegExp.test(option.label));
  const matchedRegionOptions = regions
    .map((region) => ({ label: region.name, value: region.name }))
    .filter((option) => !searchQuery || searchRegExp.test(option.label));
  const matchedOrgOptions = orgs
    .map((org) => ({ label: org.name, value: org.name }))
    .filter((option) => !searchQuery || searchRegExp.test(option.label));
  const matchedSubOrgOptions = subOrgs
    .map((subOrg) => ({ label: subOrg.name, value: subOrg.name }))
    .filter((option) => !searchQuery || searchRegExp.test(option.label));
  const matchedBuildingType = buildingTypes
    .map((building) => ({ label: building.label, value: building.label }))
    .filter((option) => !searchQuery || searchRegExp.test(option.label));

  return (
    <div>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>Site Name</Text>
        <Input
          type="text"
          placeholder="Enter site name..."
          value={formData.site}
          onChange={(label) => {
            handleChange("site", label);
          }}
          error={errors.site?.error}
          errorMessage={errors.site?.errorMessage}
        />
      </Row>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>Country</Text>
        <Select
          searchQuery={searchQuery}
          onSearch={setSearchQuery}
          placeholder="Select Country..."
          value={formData.country}
          onChange={(countries) => {
            handleChange("country", countries);
          }}
          error={errors.region?.error}
          errorMessage={errors.region?.errorMessage}
        >
          {matchedCountryOptions.map((option) => (
            <SelectOption
              label={option.label}
              value={option.value}
              key={option.value}
            />
          ))}
        </Select>
      </Row>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>Region/State/Province</Text>
        <Select
          searchQuery={searchQuery}
          onSearch={setSearchQuery}
          placeholder="Select region..."
          value={formData.region}
          onChange={(regions) => {
            handleChange("region", regions);
          }}
          error={errors.region?.error}
          errorMessage={errors.region?.errorMessage}
        >
          {matchedRegionOptions.map((option) => (
            <SelectOption
              label={option.label}
              value={option.value}
              key={option.value}
            />
          ))}
        </Select>
      </Row>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>Org</Text>
        <Select
          searchQuery={searchQuery}
          onSearch={setSearchQuery}
          placeholder="Select org..."
          value={formData.org}
          onChange={(org) => {
            handleChange("org", org);
          }}
          error={errors.org?.error}
          errorMessage={errors.org?.errorMessage}
        >
          {matchedOrgOptions.map((option) => (
            <SelectOption
              label={option.label}
              value={option.value}
              key={option.value}
            />
          ))}
        </Select>
      </Row>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>Sub Org</Text>
        <Select
          searchQuery={searchQuery}
          onSearch={setSearchQuery}
          placeholder="Select sub org..."
          value={formData.subOrg}
          onChange={(subOrg) => {
            handleChange("subOrg", subOrg);
          }}
          error={errors.subOrg?.error}
          errorMessage={errors.subOrg?.errorMessage}
        >
          {matchedSubOrgOptions.map((option) => (
            <SelectOption
              label={option.label}
              value={option.value}
              key={option.value}
            />
          ))}
        </Select>
      </Row>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>Building Type</Text>
        <Select
          searchQuery={searchQuery}
          onSearch={setSearchQuery}
          placeholder="Select building type..."
          value={formData.buildingType}
          onChange={(building) => {
            handleChange("buildingType", building);
          }}
          error={errors.buildingType?.error}
          errorMessage={errors.buildingType?.errorMessage}
        >
          {matchedBuildingType.map((option) => (
            <SelectOption
              label={option.label}
              value={option.value}
              key={option.value}
            />
          ))}
        </Select>
      </Row>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>Is Active?</Text>
        <Checkbox
          checked={formData.isActive === true}
          onChange={(isActive) => {
            handleChange("isActive", isActive, (isActive) =>
              isActive ? true : false
            );
          }}
        />
      </Row>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>ACT Available for Site?</Text>
        <Checkbox
          checked={formData.actAvailable === true}
          onChange={(actAvailable) => {
            handleChange("actAvailable", actAvailable, (actAvailable) =>
              actAvailable ? true : false
            );
          }}
        />
      </Row>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>Average Headcount</Text>
        <Input
          type="number"
          placeholder="Enter average headcount..."
          value={formData.headcount}
          onChange={(headcount) => {
            handleChange("headcount", headcount);
          }}
          error={errors.headcount?.error}
          errorMessage={errors.headcount?.errorMessage}
        />
      </Row>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>Description</Text>
        <Input
          type="text"
          placeholder="Enter description..."
          value={formData.description}
          onChange={(description) => {
            handleChange("description", description);
          }}
          error={errors.description?.error}
          errorMessage={errors.description?.errorMessage}
        />
      </Row>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>Is participating in Decision Hub pilot?</Text>
        <Checkbox
          checked={formData.participatingInDecisionHubPilot === true}
          onChange={(participatingInDecisionHubPilot) => {
            handleChange("participatingInDecisionHubPilot", participatingInDecisionHubPilot, (participatingInDecisionHubPilot) =>
            participatingInDecisionHubPilot ? true : false
            );
          }}
        />
      </Row>
    </div>
  );
};

export default siteFormFields;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PlacementDetailsService, { InitiatePlacementTransfer } from "src/services/placementDetailsService";
import JmrService from "src/services/jmr/jmrService";
import SitesService from "src/services/sites/sitesService";
import { datePickerStringToDateObj } from "src/utils/dateUtils";
import { downloadFile, createFileUrl } from "src/utils/fileUtils";
import { ErrorRecord } from "src/hooks/useForm";
import {
  AccommodationStatus,
  AgreeWithHealthCareRecommendationsEnum,
  DownloadTWPAForm,
  DownloadTWPAPayloadDecisionEnum,
  OomdForm,
  PlacementDetails,
  PlacementDetailsRevision,
  ReferralAndClaimsStatusEnum,
  SelectedPlacementFormData,
  SendEmailFormData,
  TemporaryWorkPlacementEnum,
  UpdateEndDateForm,
  EditAssociateInfoModalFormData,
  getPlacementIdByCaseIdAndLoginPayload,
} from "src/store/placementDetailsPageSlice/types";
import EmailDistributionService from "src/services/emailDistribution/emailDistributionService";
import { EmailSettings } from "src/services/emailDistribution/types/emailSettings";
import PeopleApiService from "src/services/peopleApiService";
import IncidentService from "src/services/incident/incidentService";
import { Site } from "../siteSlice";
import moment from "moment";
import { isNumber } from "src/utils/helpers";
import { AustinCasesByAlias } from "src/services/incident/types/austinCasesByAlias";

export type PlacementDetailsPageState = {
  placementId: number;
  selectedTab: string;
  placementDetails: PlacementDetails;
  isApproverInputVisible: boolean;
  isUpdateStatusModalOpen: boolean;
  isDecisionByLogin: boolean;
  selectedStatus?: SelectedPlacementFormData;
  accommodationStatus?: AccommodationStatus[];
  currAccommodation: string;
  isTwpaModalOpen: boolean;
  isOomdModalOpen: boolean;
  twpaFormData: DownloadTWPAForm;
  twpaFormErrors: ErrorRecord<DownloadTWPAForm>;
  isEmailModalOpen: boolean;
  sendEmailFormData: SendEmailFormData;
  sendEmailFormErrors: ErrorRecord<SendEmailFormData>;
  isConfirmDeleteModalOpen: boolean;
  isUpdateRestrictionDatesModalOpen: boolean;
  newEndDateForm: UpdateEndDateForm;
  emailSettings?: EmailSettings;
  jmrS3Keys: any;
  isConfirmUpdateRestrictionsModalOpen: boolean;
  isAlternativePlacementModalOpen: boolean;
  isInitiateTransferModalOpen: boolean;
  isRestrictionsModalOpen: boolean;
  isEditAssociateInfoModalOpen: boolean;
  isConfirmTransferModalOpen: boolean;
  confirmTransferPayload: InitiatePlacementTransferPayloadProps;
  editAssociateInfoModalFormData: EditAssociateInfoModalFormData;
  oomdFormData: OomdForm;
  isEditAssociateInfoSaving: boolean;
  redirectUrl: string;
  austinCasesByAlias: AustinCasesByAlias[];
  isUploadRfiModalOpen: boolean;
  bccEmailList: string[];
  transferModalEmails: string;
  updateStatusButtonDisabled: boolean;
  isSiteParticipatingInDecisionHubPilot?: boolean;
};

const initialState: PlacementDetailsPageState = {
  placementId: 0,
  selectedTab: "placement-selections",
  placementDetails: {} as PlacementDetails,
  accommodationStatus: [],
  isApproverInputVisible: false,
  isUpdateStatusModalOpen: false,
  isDecisionByLogin: false,
  selectedStatus: {
    status: "",
    id: 0,
    decisionByLoginNeeded: false,
    placementId: 0,
    approver: "",
  },
  currAccommodation: "",
  isTwpaModalOpen: false,
  isEmailModalOpen: false,
  isOomdModalOpen: false,
  twpaFormData: {} as DownloadTWPAForm,
  twpaFormErrors: {},
  sendEmailFormData: {} as SendEmailFormData,
  isUpdateRestrictionDatesModalOpen: false,
  newEndDateForm: { endDate: "" },
  sendEmailFormErrors: {},
  isConfirmDeleteModalOpen: false,
  jmrS3Keys: {},
  isConfirmUpdateRestrictionsModalOpen: false,
  isAlternativePlacementModalOpen: false,
  isInitiateTransferModalOpen: false,
  isRestrictionsModalOpen: false,
  isEditAssociateInfoModalOpen: false,
  isConfirmTransferModalOpen: false,
  confirmTransferPayload: {} as InitiatePlacementTransferPayloadProps,
  editAssociateInfoModalFormData: {} as EditAssociateInfoModalFormData,
  oomdFormData: {} as OomdForm,
  isEditAssociateInfoSaving: false,
  redirectUrl: "",
  austinCasesByAlias: [],
  isUploadRfiModalOpen: false,
  bccEmailList: [],
  transferModalEmails: "",
  updateStatusButtonDisabled: true,
  isSiteParticipatingInDecisionHubPilot: undefined,
};

/**
 * Format the placement details to UI specifications
 */
const formatPlacementDetailsPayload = (payload: PlacementDetails) => {
  const newRevisions = payload.revisions
    // round revision date to nearest minute
    .map((revision: PlacementDetailsRevision) => ({
      ...revision,
      revisionDate: new Date(revision.revisionDate).getTime(),
    }))
    // sort by revisionDate
    .sort((a: any, b: any) => new Date(a.revisionDate).getTime() - new Date(b.revisionDate).getTime());

  const data = {
    ...payload,
    startAtDate: moment(payload.startAtDate).format("L"),
    endAtDate: moment(payload.endAtDate).format("L"),
    transferredAtDate: moment(payload.transferredAtDate).format("L"),
    revisions: newRevisions,
  };
  return data;
};

/**
 * Create a map of timestamps and s3 keys
 */
const createHashMapFromS3Keys = (keys: string[]) => {
  const map: any = {};
  for (let key of keys) {
    // string is of format jmr_{accommodation_id}_{login}_{timestamp}.pdf
    const timestampPortion = key.split("_")[3];
    const timestampPortionWithoutFileExtension = +timestampPortion.split(".")[0];
    map[timestampPortionWithoutFileExtension] = key;
  }
  return map;
};

/**
 * get placement detail data
 * @returns {Object}
 */
export const getPlacementDetails = createAsyncThunk(
  "placementDetails/getPlacementDetails",
  async (accommodationId: number) => {
    const { data } = await JmrService.getAccommodationDetail(accommodationId);
    return data;
  }
);

export const getEmailsForTransferPlacement = createAsyncThunk(
  "placementDetails/getEmailsForTransfer",
  async ( destinationSiteId: number, {getState}) => {
    const state = await (getState() as any);
    const { placementDetails } = state.placementDetailsPage;
    const { allowedSites } = state.sites;
    const sourceSiteId = allowedSites.filter((site: Site) => site.site === placementDetails.fc)[0].id
    const sourceSiteEmails = await EmailDistributionService.getForSite(sourceSiteId);
    const destinationSiteEmails = await EmailDistributionService.getForSite(destinationSiteId);

    let sourceSiteApprovedEmails = sourceSiteEmails.data?.placementEmailRecipients || "";
    let destinationSiteApprovedEmails = destinationSiteEmails.data?.placementEmailRecipients || "";
    let includeManager = (sourceSiteEmails.data?.supervisorsForPlacementEmail
      || destinationSiteEmails.data?.supervisorsForPlacementEmail) || false;

    let emails = `${sourceSiteApprovedEmails};${destinationSiteApprovedEmails}`;
    emails = (includeManager) ? `${emails};${placementDetails.managerAlias}@amazon.com` : emails;
    // get rid of trailing/leading semi colon and any empty emails
    emails = emails.split(";").map(email => email.trim()).filter(email => email.length > 0).join(';');
    return emails;
  }
)


export const getPlacementIdByExternalIdAndRedirect = createAsyncThunk(
  "placementDetails/getPlacementIdByCaseIdAndLogin",
  async (payload: getPlacementIdByCaseIdAndLoginPayload) => {
    const { type, caseId, login } = payload;
    const { data } = await JmrService.getAccommodationIdByCaseIdAndLogin(type, caseId, login);
    return data;
  }
);

/**
 * download JMR
 * @returns {Object}
 */
export const downloadJMR = createAsyncThunk("placementDetails/downloadJMR", async (key: string) => {
  const { data } = await JmrService.downloadJMR(key);
  return data;
});

/**
 * Download TWPA
 */
export const downloadTWPA = createAsyncThunk("placementDetails/downloadTWPA", async (_, { getState }) => {
  const placementDetailsPageState = (await (getState() as any).placementDetailsPage) as PlacementDetailsPageState;

  const { data } = await JmrService.downloadTWPA({
    accommodationId: placementDetailsPageState.placementId,
    reportDate: placementDetailsPageState.twpaFormData.reportDate,
    placementShift: placementDetailsPageState.twpaFormData.placementShifts,
    department: placementDetailsPageState.twpaFormData.department,
    notes: placementDetailsPageState.twpaFormData.notes,
    jobModifications: placementDetailsPageState.twpaFormData.jobModifications,
    workPlacementProvide: TemporaryWorkPlacementEnum[placementDetailsPageState.twpaFormData.workPlacementProvide],
    isFullDuty: placementDetailsPageState.twpaFormData.isFullDuty,
    associateDecision: DownloadTWPAPayloadDecisionEnum[placementDetailsPageState.twpaFormData.associateDecision],
  });
  return data;
});

/**
 * Download OOMD
 */
export const downloadOOMD = createAsyncThunk("placementDetails/downloadOOMD", async (_, { getState }) => {
  const placementDetailsPageState = (await (getState() as any).placementDetailsPage) as PlacementDetailsPageState;
  const oomdFormData = placementDetailsPageState.oomdFormData;
  const { data } = await JmrService.downloadOOMD({
    accommodationId: placementDetailsPageState.placementId,
    injury: oomdFormData.injury || "",
    reportDate: oomdFormData.dateOfInjury || "",
    reportDateToAMCARE: oomdFormData.dateOfInjuryReportedToAmcare || "",
    additionalNotes: oomdFormData.additionalNotes || "",
  });
  return data;
});

/**
 * delete placement
 * @returns {Object}
 */
export const deletePlacement = createAsyncThunk("placementDetails/removePlacement", async (accommodationId: number) => {
  const { data } = await JmrService.deleteAccommodation(accommodationId);
  return data;
});

/**
 * get accommodation statuses
 * @returns {Object}
 */
export const getAccommodationStatus = createAsyncThunk(
  "placementDetails/getAccommodationStatus",
  async (siteId: number) => {
    const { data } = await PlacementDetailsService.getAccommodationStatus(siteId);
    return data;
  }
);

/**
 * get accommodation statuses for accommodation given an accommodation id
 * @returns {Object}
 */
export const getAccommodationStatusForAccommodation = createAsyncThunk(
  "placementDetails/getAccommodationStatusForAccommodation",
  async(accommodationId: number) => {
    const { data } = await PlacementDetailsService.getAccommodationStatusForAccommodation(accommodationId);
    return data;
  }
)

/**
 * update accommodation status
 * @returns {Object}
 */
export const updateAccommodationStatus = createAsyncThunk(
  "placementDetails/updateAccommodationStatus",
  async (_, { getState }) => {
    const state: any = getState();
    const updatedStatus = state.placementDetailsPage.selectedStatus;
    const { approver, id, placementId } = updatedStatus;
    const payload = {
      accommodationId: +placementId,
      accommodationStatusId: id,
      decisionByLogin: approver,
    };
    const responseData = await PlacementDetailsService.updateAccommodationStatus(payload);
    return responseData;
  }
);

/**
 * update accommodation status
 * @returns {Object}
 */
export const updateAccommodationStatusV2 = createAsyncThunk(
  "placementDetails/updateAccommodationStatusV2",
  async (_, { getState }) => {
    const state: any = getState();
    const updatedStatus = state.placementDetailsPage.selectedStatus;
    const { approver, id, placementId } = updatedStatus;
    const payload = {
      accommodationId: +placementId,
      accommodationStatusId: id,
      decisionByLogin: approver,
    };
    const responseData = await PlacementDetailsService.updateAccommodationStatusV2(payload);
    return responseData;
  }
);

/**
 * update accommodation with associate information change
 * @returns {Object}
 */
export const updateAssociateInformation = createAsyncThunk(
  "placementDetails/updateAssociateInformation",
  async (_, { getState }) => {
    const state: any = getState();
    const accommodationId = state.placementDetailsPage.placementId;
    const editAssociateInfoFormData = state.placementDetailsPage.editAssociateInfoModalFormData;

    const editAssociateInfoFormDataPayload = {
      accommodationId: accommodationId,
      login: editAssociateInfoFormData.login,
      shiftCode: editAssociateInfoFormData.homeShift,
      externalCaseId: editAssociateInfoFormData.caseId,
      caseSource: editAssociateInfoFormData.caseSource,
      job: editAssociateInfoFormData.job,
      employeeId: editAssociateInfoFormData.employeeId,
      personId: editAssociateInfoFormData.personId,
      fullName: editAssociateInfoFormData.fullName,
    };

    const responseData = await JmrService.updateAssociateInfo(editAssociateInfoFormDataPayload);
    return responseData;
  }
);

// map the enums to the value the backend expects
const getReferralClaimsStatus = (status: ReferralAndClaimsStatusEnum) => {
  if (status !== undefined) {
    if (status === ReferralAndClaimsStatusEnum.HasHadWorkRelatedClaimDenied) {
      return "DENY_CLAIM";
    } else if (status === ReferralAndClaimsStatusEnum.HasPermanentRestrictions) {
      return "REQUIRE_DLS";
    } else if (status === ReferralAndClaimsStatusEnum.HasReachedLimit) {
      return "REACH_LIMIT";
    }
  }
  return "";
};

// map the enums to the value the backend expects
const getAgreeWithHealthCareRecommendations = (status: AgreeWithHealthCareRecommendationsEnum) => {
  if (status !== undefined) {
    if (status === AgreeWithHealthCareRecommendationsEnum.IsUncertain) {
      return "UNCERTAIN";
    } else if (status === AgreeWithHealthCareRecommendationsEnum.DoesNotAgree) {
      return "NOT_AGREE";
    }
  }
  return "";
};

/**
 * update accommodation email
 * @returns {Object}
 */
export const updateAccommodationEmail = createAsyncThunk(
  "placementDetails/updateAccommodationEmail",
  async (_, { getState }) => {
    const { placementId, sendEmailFormData } = await (getState() as any).placementDetailsPage;
    const payload = {
      accommodationId: +placementId,
      emailType: sendEmailFormData.emailType,
      emailRecipient: sendEmailFormData.emailRecipients,
      placementDetails: sendEmailFormData.placementDetails || sendEmailFormData.actionsForHr,
      dlsStatus: getReferralClaimsStatus(sendEmailFormData.referralAndClaimsStatus),
      decision: getAgreeWithHealthCareRecommendations(sendEmailFormData.agreeWithHealthCareRecommendations),
      actionAreaManager: sendEmailFormData.areaManagerActionsNeeded,
      firstDayReturn: sendEmailFormData.firstDayReturn,
      lastDayWorked: sendEmailFormData.lastDayWorked,
      firstDayAbsent: sendEmailFormData.firstDayAbsent,
      permanentRestrictionDate: sendEmailFormData.dateOfPermanentRestrictions,
    };
    const { data } = await PlacementDetailsService.updateEmailStatus(payload);
    return data;
  }
);

type InitiatePlacementTransferPayloadProps = {
  destinationSiteId:  number;
  emailRecipients: string;
}

export const initiatePlacementTransfer = createAsyncThunk(
  "placementDetails/initiatePlacementTransfer",
  async(_, { getState }) => {
    const state = await (getState() as any);
    const { placementId, placementDetails, confirmTransferPayload } = await (getState() as any).placementDetailsPage;
    const { allowedSites } = state.sites;
    const sourceSiteId = allowedSites.filter((site: Site) => site.site === placementDetails.fc)[0].id
    const payload: InitiatePlacementTransfer = {
      accommodationId: placementId,
      emailRecipients: confirmTransferPayload.emailRecipients,
      originSiteId: sourceSiteId,
      destinationSiteId: confirmTransferPayload.destinationSiteId
    };
    const { status } = await PlacementDetailsService.initiatePlacementTransfer(payload);
    return { status, fc: placementDetails.fc}
  }
);

/**
 * Get email settings by site
 */
export const getEmailSettingsForSite = createAsyncThunk(
  "placementDetails/getEmailSettings",
  async (_, { getState }) => {
    const state = await (getState() as any);
    const { placementDetails } = state.placementDetailsPage;
    const { allowedSites } = state.sites;
    const { data } = await EmailDistributionService.getForSite(
      allowedSites.filter((site: Site) => site.site === placementDetails.fc)[0].id
    );
    return data;
  }
);

/**
 * update placement end date
 * @returns {Object}
 */
export const updatePlacementEndDate = createAsyncThunk(
  "placementDetails/updateAccommodationEndDate",
  async (newDate: string, { getState }) => {
    const { placementId } = await (getState() as any).placementDetailsPage;
    const timeStamp = datePickerStringToDateObj(newDate)?.getTime();
    if (timeStamp) {
      const payload = {
        accommodationId: +placementId,
        endDate: timeStamp,
      };
      const { data } = await PlacementDetailsService.updatePlacementEndDate(payload);
      return data;
    }
  }
);

/**
 * get s3 keys for accommodation
 * @returns {Object}
 */
export const getAccommodationKeys = createAsyncThunk(
  "placementDetails/getAccommodationKeys",
  async (accommodationId: number) => {
    const { data } = await PlacementDetailsService.getAccommodationKeys(accommodationId);
    return data;
  }
);

/**
 * get employee info from people api
 * @returns {Object}
 */
export const getEmployeeInfo = createAsyncThunk("placementDetails/getEmployeeInfo", async (employeeLogin: string) => {
  const { data } = await PeopleApiService.getEmployeeInfo(employeeLogin);
  return data;
});

export const getAustinCasesByAlias = createAsyncThunk(
  "placementDetails/getAustinCasesByAlias",
  async (alias: string) => {
    const { data } = await IncidentService.getAustinCasesByAlias(alias);
    return data;
  }
);

export const getUpdateRestrictionAllowed = createAsyncThunk(
  "placementDetails/getUpdateRestrictionAllowed",
  async (accommodationId: number) => {
    const { data } = await JmrService.getUpdateRestrictionAllowed(accommodationId);
    return data;
  }
);

export const getBccEmailList = createAsyncThunk(
    "placementDetails/getBccEmailList",
    async (payload: {placementId: number, emailType: string}, ) => {
      const { data } = await JmrService.getBccEmailById(payload.placementId, payload.emailType);
      return data;
    }
);

export const checkIfSiteOnboardedToDecisionHub = createAsyncThunk(
    "placementDetails/checkIfSiteOnboardedToDecisionHub",
    async (siteId: number) => {
      const {data} = await SitesService.getSiteById(siteId);
      return data.participatingInDecisionHubPilot;
    }
);

/** Placement Details Page Slice */
const { reducer, actions } = createSlice({
  name: "placementDetailsPageSlice",
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setPlacementDetails: (state, action) => {
      state.placementDetails = action.payload;
    },
    setIsApproverInputVisible: (state, action) => {
      state.isApproverInputVisible = action.payload;
    },
    setIsUpdateStatusModalOpen: (state, action) => {
      state.isUpdateStatusModalOpen = action.payload;
    },
    setSelectedStatus: (state, action) => {
      state.selectedStatus = action.payload;
    },
    setIsDecisionByLogin: (state, action) => {
      state.isDecisionByLogin = action.payload;
    },
    setAccommodationStatus: (state, action) => {
      state.accommodationStatus = action.payload;
    },
    setCurrAccommodation: (state, action) => {
      state.currAccommodation = action.payload;
    },
    setTwpaFormData: (state, action) => {
      state.twpaFormData = action.payload;
    },
    setTwpaFormErrors: (state, action) => {
      state.twpaFormErrors = action.payload;
    },
    setIsTwpaModalOpen: (state, action) => {
      state.isTwpaModalOpen = action.payload;
    },
    setIsEmailModalOpen: (state, action) => {
      state.isEmailModalOpen = action.payload;
    },
    setIsOomdModalOpen: (state, action) => {
      state.isOomdModalOpen = action.payload;
    },
    setUploadRfiModalOpen: (state, action) => {
      state.isUploadRfiModalOpen = action.payload;
    },
    setPlacementId: (state, action) => {
      state.placementId = action.payload;
    },
    setSendEmailFormData: (state, action) => {
      state.sendEmailFormData = action.payload;
    },
    setSendEmailFormErrors: (state, action) => {
      state.sendEmailFormErrors = action.payload;
    },
    setIsConfirmDeleteModalOpen: (state, action) => {
      state.isConfirmDeleteModalOpen = action.payload;
    },
    setIsUpdateRestrictionDatesModalOpen: (state, action) => {
      state.isUpdateRestrictionDatesModalOpen = action.payload;
    },
    setNewEndDateForm: (state, action) => {
      state.newEndDateForm = action.payload;
    },
    setJmrS3Keys: (state, action) => {
      state.jmrS3Keys = action.payload;
    },
    setIsConfirmUpdateRestrictionsModalOpen: (state, action) => {
      state.isConfirmUpdateRestrictionsModalOpen = action.payload;
    },
    setIsAlternativePlacementModalOpen: (state, action) => {
      state.isAlternativePlacementModalOpen = action.payload;
    },
    setIsInitiateTransferModalOpen: (state, action) => {
      state.isInitiateTransferModalOpen = action.payload;
    },
    setIsRestrictionsModalOpen: (state, action) => {
      state.isRestrictionsModalOpen = action.payload;
    },
    setIsConfirmTransferModalOpen: (state, action) => {
      state.isConfirmTransferModalOpen = action.payload;
    },
    setConfirmTransferPayload: (state, action) => {
      state.confirmTransferPayload = action.payload;
    },
    setOomdFormData: (state, action) => {
      state.oomdFormData = action.payload;
    },
    setIsEditAssociateInfoModalOpen: (state, action) => {
      state.isEditAssociateInfoModalOpen = action.payload;
    },
    setAssociateInfoFormData: (state, action) => {
      state.editAssociateInfoModalFormData = action.payload;
    },
    setIsEditAssociateInfoSaving: (state, action) => {
      state.isEditAssociateInfoSaving = action.payload;
    },
    setRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload;
    },
    setIsUpdateStatusButtonDisabled: (state, action) => {
      state.updateStatusButtonDisabled = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPlacementDetails.fulfilled, (state, { payload }) => {
      state.placementDetails = formatPlacementDetailsPayload(payload);
    });
    builder.addCase(getAccommodationStatus.fulfilled, (state, { payload }) => {
      state.accommodationStatus = [...payload]?.sort((a: AccommodationStatus, b: AccommodationStatus) => {
        return a.status.localeCompare(b.status);
      });
    });
    builder.addCase(getAccommodationStatusForAccommodation.fulfilled, (state, { payload }) => {
      state.accommodationStatus = [...payload]?.sort((a: AccommodationStatus, b: AccommodationStatus) => {
        return a.status.localeCompare(b.status);
      });
    });
    builder.addCase(downloadJMR.fulfilled, (_, { payload }) => {
      if (payload) {
        // download file
        downloadFile(createFileUrl(payload, "application/pdf"), "jmr-report.pdf");
      }
    });
    builder.addCase(downloadTWPA.fulfilled, (state, { payload }) => {
      if (payload) {
        // download file
        downloadFile(createFileUrl(payload, "application/pdf"), "twpa-form.pdf");
        state.isTwpaModalOpen = false;
      }
    });
    builder.addCase(downloadOOMD.fulfilled, (state, { payload }) => {
      if (payload) {
        // download file
        downloadFile(createFileUrl(payload, "application/pdf"), "oomd-form.pdf");
        state.isOomdModalOpen = false;
      }
    });
    builder.addCase(updateAccommodationEmail.fulfilled, (state) => {
      state.isEmailModalOpen = false;
    });
    builder.addCase(getEmailSettingsForSite.fulfilled, (state, { payload }) => {
      state.emailSettings = payload;
    });
    builder.addCase(updatePlacementEndDate.fulfilled, (state) => {
      state.isUpdateRestrictionDatesModalOpen = false;
    });
    builder.addCase(getAccommodationKeys.fulfilled, (state, { payload }) => {
      state.jmrS3Keys = createHashMapFromS3Keys(payload);
    });
    builder.addCase(getBccEmailList.fulfilled, (state, { payload }) => {
      state.bccEmailList = payload;
    });
    builder.addCase(getEmployeeInfo.fulfilled, (state, { payload }) => {
      state.editAssociateInfoModalFormData.employeeId = payload.basicInfo?.legacyEmployeeId;
      state.editAssociateInfoModalFormData.job = payload.basicInfo?.businessTitle;
      state.editAssociateInfoModalFormData.homeShift = payload.job?.workScheduleId;
      state.editAssociateInfoModalFormData.fullName = `${payload.basicInfo?.firstName} ${payload.basicInfo?.lastName}`;
    });
    builder.addCase(getPlacementIdByExternalIdAndRedirect.fulfilled, (state, { payload }) => {
      if (isNumber(payload)) {
        state.redirectUrl = `/placementDetails/${payload}`;
      } else {
        state.redirectUrl = `/newJMR`;
      }
    });
    builder.addCase(getAustinCasesByAlias.fulfilled, (state, { payload }) => {
      state.austinCasesByAlias = payload;
    });
    builder.addCase(getEmailsForTransferPlacement.fulfilled, (state, {payload}) => {
      state.transferModalEmails = payload;
    });
    builder.addCase(initiatePlacementTransfer.fulfilled, (state, { payload }) => {
      if(payload.status === 200) {
        state.redirectUrl = `/placementsOverview/${payload.fc}/pendingPlacements`
      }
    });
    builder.addCase(checkIfSiteOnboardedToDecisionHub.fulfilled, (state, {payload}) => {
      state.isSiteParticipatingInDecisionHubPilot = payload;
    })
  },
});

export const {
  setSelectedTab,
  setPlacementDetails,
  setIsApproverInputVisible,
  setIsUpdateStatusModalOpen,
  setSelectedStatus,
  setIsDecisionByLogin,
  setAccommodationStatus,
  setCurrAccommodation,
  setIsTwpaModalOpen,
  setTwpaFormData,
  setTwpaFormErrors,
  setPlacementId,
  setIsEmailModalOpen,
  setSendEmailFormErrors,
  setSendEmailFormData,
  setIsConfirmDeleteModalOpen,
  setIsUpdateRestrictionDatesModalOpen,
  setNewEndDateForm,
  setIsConfirmUpdateRestrictionsModalOpen,
  setIsAlternativePlacementModalOpen,
  setIsInitiateTransferModalOpen,
  setIsRestrictionsModalOpen,
  setIsConfirmTransferModalOpen,
  setConfirmTransferPayload,
  setIsOomdModalOpen,
  setUploadRfiModalOpen,
  setOomdFormData,
  setIsEditAssociateInfoModalOpen,
  setAssociateInfoFormData,
  setIsEditAssociateInfoSaving,
  setRedirectUrl,
  setIsUpdateStatusButtonDisabled
} = actions;

export default reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SitesService from "src/services/sites/sitesService";
import UserSiteConfigService from "src/services/userSiteConfigService";
import { SortStrategy, sortBasedOnStrategy } from "src/utils/sortStrategies";

export interface Site {
  site: string;
  id: number;
  regionId: number;
  countryId: number;
  participatingInDecisionHubPilot?: boolean;
}

export interface SiteLabel {
  label: string;
  value: number;
}

export const NO_SITE = { site: "", id: 0, regionId: 0, countryId: 0 };

export type SiteState = {
  allSites: Site[];
  userSites: Site[];
  allowedSites: Site[];
  selectedSite: Site;
  isSiteSelectionDisabled: boolean;
  preferredSitesAndAllowedSitesFetched: boolean;
  hasAllowedSite: boolean;
  activeSites: Site[];
};

const initialState: SiteState = {
  allowedSites: [],
  allSites: [],
  userSites: [],
  selectedSite: NO_SITE,
  isSiteSelectionDisabled: false,
  preferredSitesAndAllowedSitesFetched: false,
  hasAllowedSite: true,
  activeSites: []
};

export const getAllowedAndPreferredSites = createAsyncThunk(
  "site/getAllowedAndPreferredSites",
  async (alias: string) => {
    const { data: allowedSites } = await UserSiteConfigService.getAllowedSites();
    let { data: preferredSites } = await UserSiteConfigService.getUserSiteConfig(alias);
    // preferredSites will be "" before the user has saved anything in the default settings section of RTW
    // Note that there is a difference between "saving no preferred site" and "before saving any preferred site"
    preferredSites = (preferredSites === "") ? {defaultSites: []} : preferredSites;
    return { allowedSites, preferredSites};
  }
);

/**
 * get all the sites
 * @returns {Object}
 */
export const getAllowedSites = createAsyncThunk(
  "site/getAllowedSites",
  async () => {
    const { data } = await UserSiteConfigService.getAllowedSites();
    return data;
  }
);

/**
 * get all the sites
 * @returns {Object}
 */
export const getSites = createAsyncThunk("site/getAllSites", async () => {
  const { data } = await SitesService.getSites();
  return data;
});

/**
 * get all active sites
 */
export const getAllActiveSites = createAsyncThunk("site/getAllActive", async () => {
  const { data } = await SitesService.getActiveSites();
  return data;
});

/**
 * get this users sites
 * @returns {Object}
 */
interface getUserSitesParamType {
  alias: string;
}
export const getUserSites = createAsyncThunk(
  "sites/getUserSites",
  async ({ alias }: getUserSitesParamType) => {
    const { data } = await UserSiteConfigService.getUserSiteConfig(alias);
    return data == "" ? { defaultSites: [] } : data;
  }
);

/**
 * update the sites
 * @returns {Object}
 */
interface updatedUserSitesParamType {
  alias: string;
  sites: Site[];
}
export const updateUserSites = createAsyncThunk(
  "sites/updateUserSites",
  async ({ alias, sites }: updatedUserSitesParamType) => {
    const { data } = await UserSiteConfigService.updateUserSites(alias, sites);
    return data;
  }
);

/** Site Slice */
const { reducer, actions } = createSlice({
  name: "sites",
  initialState,
  reducers: {
    setSelectedSite: (state, action) => {
      state.selectedSite = action.payload;
    },
    setIsSiteSelectionDisabled: (state, action) => {
      state.isSiteSelectionDisabled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSites.fulfilled, (state, { payload }) => {
      state.allSites = payload;
    });
    builder.addCase(getAllowedSites.fulfilled, (state, { payload }) => {
      if (payload.length === 0) {
        state.hasAllowedSite = false;
      }
      state.allowedSites = payload;
    });
    builder.addCase(getUserSites.fulfilled, (state, { payload }) => {
      state.userSites = payload.defaultSites;
    });
    builder.addCase(updateUserSites.fulfilled, (state, { payload }) => {
      state.userSites = payload.defaultSites;
    });
    builder.addCase(getAllowedAndPreferredSites.fulfilled, (state, {payload}) => {
      state.userSites = payload.preferredSites.defaultSites;
      state.allowedSites = payload.allowedSites;
      if (state.allowedSites.length === 0) {
        state.hasAllowedSite = false;
      } else {
        state.preferredSitesAndAllowedSitesFetched = true;
      }
    });
    builder.addCase(getAllowedAndPreferredSites.rejected, (state, {payload}) => {
        state.hasAllowedSite = false;
    });
    builder.addCase(getAllActiveSites.fulfilled, (state, { payload }) => {
      state.activeSites = sortBasedOnStrategy(SortStrategy.DEFAULT, payload, "site", "ascending");
    });
  },
});

export const { setSelectedSite, setIsSiteSelectionDisabled } = actions;

export default reducer;
